.App {
  text-align: left;
  font-family: 'Georgia', serif;
  color: #1a2a3a;
  line-height: 1.6;
}

.App-nav {
  background-color: #1a2a3a;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-nav ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

.App-nav a {
  color: #e0e0e0;
  text-decoration: none;
  font-weight: normal;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease;
}

.App-nav a:hover {
  color: #ffffff;
}

.App-logo {
  height: 40px;
}

.App-header {
  background-color: #f8f9fa;
  padding: 4rem 2rem;
  color: #1a2a3a;
  text-align: center;
}

.App-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: normal;
}

.App-header h2 {
  font-size: 1.2rem;
  font-weight: normal;
  color: #4a5a6a;
}

.App-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.App-main section {
  margin-bottom: 4rem;
}

.App-main h3 {
  color: #1a2a3a;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: normal;
}

.App-main p, .App-main ul {
  font-size: 1rem;
  color: #4a5a6a;
  margin-bottom: 1.5rem;
}

.App-main ul {
  padding-left: 1.5rem;
}

button {
  background-color: #1a2a3a;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2c3e50;
}

.App-footer {
  background-color: #f8f9fa;
  color: #4a5a6a;
  padding: 2rem;
  text-align: center;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .App-nav {
    flex-direction: column;
    padding: 1rem;
  }

  .App-nav ul {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .App-nav li {
    margin: 0.5rem 0;
  }

  .App-header {
    padding: 2rem 1rem;
  }

  .App-main {
    padding: 2rem 1rem;
  }
}

.page-image {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-container {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.cta-button, .secondary-button {
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button {
  background-color: #1a2a3a;
  color: white;
}

.cta-button:hover {
  background-color: #2c3e50;
}

.secondary-button {
  background-color: transparent;
  color: #1a2a3a;
  border: 1px solid #1a2a3a;
}

.secondary-button:hover {
  background-color: #1a2a3a;
  color: white;
}

#home ul {
  list-style-type: none;
  padding-left: 0;
}

#home ul li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

#home ul li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #1a2a3a;
}
